mark {
  background-color: rgb(136, 212, 241);
}

mark:hover {
  font-size: 45px;
  background-color: #4fc3f7;
  cursor: grab;
}

.skills-row {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 75px;
  margin-bottom: 100px;
}

.skills {
  height: fit-content;
  background-color: white;
  color: black;
  font-family: "Raleway", sans-serif;
  padding-top: 80px;
  margin-bottom: 140px;
}

.skillsContainerHeader {
  font-size: 100px;
  text-align: center;
  text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
}

.skillsContainerSubHeader {
  font-size: 30px;
  text-align: center;
  text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
}

.skills-desc {
  font-size: 18px;
  text-align: center;
  line-height: 150%;
}

.skills-desc-links {
  font-size: 30px;
  text-align: center;
}

.snippet {
  text-align: center;
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
}
.sub-snippet-card {
  margin-bottom: 20px;
}

#header-break {
  width: 50%;
  border-width: 0.3em;
  border-color: black;
}

.icons {
  font-size: 60px;
}

@media only screen and (max-width: 600px) {
  .skillsContainerHeader {
    font-size: 50px;
    text-align: center;
    text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
  }

  .skillsContainerSubHeader {
    font-size: 12px;
    text-align: center;
    text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
  }

  .icons {
    font-size: 30px;
  }

  .skills-desc {
    font-size: 12px;
    text-align: center;
  }
  .skills-row {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 35px;
  }

  .skills-desc-links {
    font-size: 20px;
    text-align: center;
  }
}
