.jumbo {
  height: 100vh;
  background-color: black;
  background-image: url(/images/coffee.jpg);
  background-size: cover;
  position: relative;
  text-align: center;
  padding-top: 250px;
  margin-bottom: 0 !important;
}

.nameLead {
  color: #4fc3f7;
  font-size: 100px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  position: absolute;
  top: 0px;
  right: -150px;
  margin-right: 80px;
}

.titleLead {
  color: white;
  font-size: 40px;
  font-style: bold;
  font-family: "Raleway", sans-serif;
  text-shadow: -1px -1px 3px #020202, 2px 2px 4px #4fc3f7;
  position: absolute;
  top: 125px;
  right: 70px;
}

@media only screen and (max-width: 1024px) {
  .jumbo {
    padding-top: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .nameLead {
    font-size: 80px;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
  }

  .titleLead {
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 85px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 960px) {
  .jumbo {
    padding-top: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .nameLead {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
  }

  .titleLead {
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 85px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .jumbo {
    padding-top: 100px;
  }
  .nameLead {
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
  }

  .titleLead {
    font-size: 35px;
    position: absolute;
    left: 0px;
    top: 55px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .jumbo {
    padding-top: 100px;
  }
  .nameLead {
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    text-align: center;
  }

  .titleLead {
    font-size: 35px;
    position: absolute;
    left: 0px;
    top: 100px;
    width: 100%;
    text-align: center;
  }
}
