.contact-page {
  background-color: black;
  height: 100vh;
  color: white;
  font-size: 40px;
  text-align: center;
  font-family: "Raleway", sans-serif;
  padding-top: 250px;
  margin-left: 0px;
  margin-right: 0px;
}

#contact-break {
  background-color: white;
}

.contact-subtext {
  font-size: 20px;
}

.contact-align {
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .contact-page {
    padding-top: 40px;
    font-size: 30px;
    height: 100vh;
  }

  .contact-section {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-text {
    font-size: 20px;
  }
}
