.projContainer {
  height: fit-content;
  background-color: white;
  background-image: url(/images/background-cement-concrete-paint-242236.jpg);
  font-family: "Raleway", sans-serif;
  padding-bottom: 40px;
  padding-top: 80px;
}
.projContainerHeader {
  font-size: 100px;
  text-align: center;
  text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
}

.projContainerSubHeader {
  font-size: 30px;
  text-align: center;
  text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
}

#header-break {
  width: 50%;
  border-width: 0.3em;
  border-color: black;
}

@media only screen and (max-width: 600px) {
  .projContainer {
    padding-bottom: 20px;
  }
  .projContainerHeader {
    font-size: 50px;
    text-align: center;
    text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
  }

  .projContainerSubHeader {
    font-size: 12px;
    text-align: center;
    text-shadow: -1px -1px 3px white, 2px 2px 4px #1b1b1b;
  }
}
