.navbar {
  background-color: transparent !important;
}

i {
  color: #4fc3f7;
  font-size: 30px;
}

a {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
