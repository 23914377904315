.image {
  width: 100%;
  opacity: 1;
}

img {
  vertical-align: middle;
  border-style: solid;
  border-color: black;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.projectRow {
  padding-top: 100px;
  margin-bottom: 100px;
}

.project-name {
  text-align: center;
}

.description {
  font-size: 20px;
  text-align: center;
}

.img-view-app {
  background-color: white;
  color: black;
  border-color: black;
  border-style: solid;
  border-width: 0.15em;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  font-family: "Raleway", sans-serif;
}
.img-view-app:hover {
  background-color: black;
  color: white;
  border-color: rgb(228, 226, 226);
}

.button-group {
  display: flex;
  justify-content: center;
}

Button {
  margin: 8px;
}

@media only screen and (max-width: 600px) {
  .projectRow {
    padding-top: 25px;
    margin-bottom: 50px;
  }

  .project-name {
    font-size: 35px;
    margin-top: 15px;
  }
  .description {
    font-size: 12px;
    text-align: center;
  }
}
